@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

table,
th,
td {
  border: "1px solid black";
}

th,
td {
  /* textAlign: "center", */
  border: "1px solid black";
}

tr.rc-table-row.rc-table-row-level-0.invoiceRow td {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 11px;
  height: 7px;
  text-align: left;
  border-bottom: 1px solid rgb(124, 124, 124);
  border-collapse: separate;
  border-spacing: 5px 5px;
}

tr.rc-table-row.invoiceRow:last-child td {
  border-bottom: none;
  border-collapse: separate;
  border-spacing: 0px 0px;
}

tr.rc-table-row.invoiceRow p {
  padding: 0 !important;
  margin: 2px;
  text-align: left;
  min-width: 70px;
  max-width: 130px;
}

thead.rc-table-thead th {
  font-size: 12px;
  max-width: 100px;
  text-align: left !important;
  border-bottom: 1px solid rgb(124, 124, 124);
  border-collapse: separate;
  border-spacing: 5px 5px;
}

thead.rc-table-thead th:last-child {
  text-align: right !important;
}

tr.rc-table-row.invoiceRow td:last-child p:last-child {
  text-align: right !important;
}

.rc-table-content {
  margin: 10px !important;
}

.rc-table-content table {
  border: 0;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.custom-data-point {
  font-size: 13px;
  display: flex;
  flex-direction: column;
}

.custom-data-point span {
  display: flex;
}

.custom-data-point span div {
  font-weight: bold;
  width: 60px;
}